<template>
  <nav aria-label="Page navigation example">
    <ul class="pagination">
      <!-- Previous button -->
      <li
        class="page-item"
        :class="{ disabled: +currentPage === 1 }"
      >
        <a
          class="page-link"
          href="#"
          @click.prevent="onPageChange(currentPage - 1)"
        >
          <slot name="prev-text">
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </slot>
        </a>
      </li>

      <!-- Show first page and ellipsis before -->
      <li
        v-if="showEllipsisBefore && totalPages > 1"
        class="page-item"
      >
        <a
          class="page-link"
          href="#"
          @click.prevent="onPageChange(1)"
        >
          {{ 1 }}
        </a>
      </li>
      <li
        v-if="showEllipsisBefore && totalPages > 1"
        class="page-item disabled"
      >
        <span class="page-link">...</span>
      </li>

      <!-- Dynamic page buttons -->
      <li
        v-for="page in visiblePages"
        :key="page"
        class="page-item"
        :class="{ active: +page === +currentPage }"
      >
        <a
          class="page-link"
          href="#"
          @click.prevent="onPageChange(page)"
        >
          {{ page }}
        </a>
      </li>

      <!-- Show ellipsis after and last page -->
      <li
        v-if="showEllipsisAfter && totalPages > 1"
        class="page-item disabled"
      >
        <span class="page-link">...</span>
      </li>
      <li
        v-if="showEllipsisAfter && totalPages > 1"
        class="page-item"
      >
        <a
          class="page-link"
          href="#"
          @click.prevent="onPageChange(totalPages)"
        >
          {{ totalPages }}
        </a>
      </li>

      <!-- Next button -->
      <li
        class="page-item"
        :class="{ disabled: +currentPage === +totalPages }"
      >
        <a
          class="page-link"
          href="#"
          @click.prevent="onPageChange(+currentPage + 1)"
        >
          <slot name="next-text">
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </slot>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalRows: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalRows / this.perPage)
    },
    visiblePages() {
      const pages = []
      const range = 1 // Number of pages to show before and after the current page
      const totalPageButtons = 7 // Total number of page buttons to display (including ellipses)

      if (this.totalPages <= totalPageButtons) {
        // If there are fewer pages than the total number of buttons, show all pages
        for (let i = 1; i <= this.totalPages; i++) {
          pages.push(i)
        }
      } else {
        // Handle large number of pages
        let start = Math.max(+this.currentPage - range, 1)
        let end = Math.min(+this.currentPage + range, +this.totalPages)

        if (+this.currentPage <= +range + 1) {
          start = 1
          end = Math.min(+totalPageButtons - 2, this.totalPages)
        }
        if (+this.currentPage >= +this.totalPages - range) {
          start = Math.max(+this.totalPages - totalPageButtons + 3, 1)
          end = this.totalPages
        }
        if (+this.currentPage < totalPageButtons - 2){
          start = 1
          end = 5
        }
        // Construct the pages array
        for (let i = start; i <= end; i++) {
          pages.push(i)
        }
      }
      return pages
    },
    showEllipsisBefore() {
      return this.totalPages > 7 && this.visiblePages[0] > 2
    },
    showEllipsisAfter() {
      return this.totalPages > 7 && this.visiblePages[this.visiblePages.length - 1] < this.totalPages - 1
    },
    showLastPage() {
      return this.totalPages > 7 && !this.visiblePages.includes(this.totalPages)
    },
  },
  methods: {
    onPageChange(page) {
      if (page >= 1 && page <= this.totalPages && page !== this.currentPage) {
        this.$emit('page-change', page)
      }
    },
  },
}
</script>
