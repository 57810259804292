import { localize } from "vee-validate"
import { debouncedFunction100 } from "@/shared/utils"
import i18n from "@/libs/i18n"

export default {
    watch: {
        '$i18n.locale': function (newLocale) {
            this.updateValidationLocale(newLocale)
        },
    },
    methods: {
        updateValidationLocale(locale) {
            debouncedFunction100(() => {
                localize(locale, {
                    messages: i18n.getLocaleMessage(locale).messages,
                })
            })
        },
    },
    created() {
        this.updateValidationLocale(this.$i18n.locale)
    },
}
