import i18n from "@/libs/i18n"

export const debounce = (fn, wait) => {
    let timeoutID
    return (...arg) => {
        if (timeoutID) clearTimeout(timeoutID)
        timeoutID = setTimeout(() => fn(...arg), wait)
    }
}
export const locales = {
    1: 'hy',
    2: 'en',
}
export const localesKeyValue = {
    'hy': 1,
    'en': 2,
}
export const getLocaleKey = id => locales[id]

// Function to get translation with specific locale without changing the language
export const getTranslation = (key, targetLocale) => i18n._t(key, targetLocale, i18n.messages, null)
export const  getYouTubeVideoId = (url) => {
    // Regex for embed and youtu.be short links
    const embedOrShortRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/embed\/|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    // Regex for watch?v= links
    const watchRegex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})/;

    let match = url.match(embedOrShortRegex) || url.match(watchRegex);
    return match ? match[1] : null; // Return the video ID or null if not found
}
export const debounceFirstImmediate = (func, wait) => {
    let timeout = null
    let firstCall = true

    return function executedFunction(...args) {
        const later = () => {
            timeout = null
            if (!firstCall) {
                func.apply(this, args)
            }
        }

        const callNow = firstCall
        firstCall = false

        clearTimeout(timeout)
        timeout = setTimeout(later, wait)

        if (callNow) {
            func.apply(this, args)
        }
    }
}
