<template>

  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="reloadTableData"
    />
    <!-- Filters -->
    <!-- <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >

                <span class="text-muted">
                  {{ $t('tabs.showing', { from: currentPage !== 1 ? perPage * currentPage : 1 , to: currentPage !== 1 ? perPage * currentPage + perPage : perPage * currentPage }) }}
                </span>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                @input="onPerPageChange"
            >
              <span slot="no-options">{{ $t('fields.noSelect') }}</span>
            </v-select>
            <span class="text-muted">
                  {{ $t('tabs.to', { all: totalRows }) }}
                </span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">{{ $t('buttons.addAdmin') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <!-- table -->
      <vue-good-table
        mode="remote"
        :columns="columns"
        :rows="rows"
        :page="currentPage"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }"
        :pagination-options="{
          enabled: true,
          perPage: perPage
        }"
        :total-rows="totalRows"
        :is-loading.sync="isLoading"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
      >
        <template
          slot="column-filter"
          slot-scope="props"
        >
          <span
            v-if="props.column.filterOptions.customFilter === 'LastLoginDate'"
          >
            <b-input-group
              class="input-group-merge"
              size="sm"
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-prepend>
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                :config="{dateFormat: 'm-d-Y',}"
                @on-change="filterDate()"
              />
            </b-input-group>

          </span>
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span
            v-if="props.column.field === 'fullName'"
            class="text-nowrap"
          >
            <b-avatar
              :src="props.row.avatar ? props.row.avatar : null"
              class="mx-1"
            />
            <span class="text-nowrap">
              <span
                v-if="props.row.fullName"
                class="text-nowrap"
              >{{ props.row.fullName }}</span>
              <span
                v-else-if="props.row.fullName === null || props.row.fullName === undefined "
                class="text-nowrap"
              >{{ $t('fields.noName') }}</span>
            </span>
          </span>

          <span
            v-else-if="props.column.field === 'status'"
            class="d-flex justify-content-center"
          >
            <b-badge
              pill
              :variant="`light-${getStatus(props.row.status).value}`"
              class="text-capitalize"
            >
              {{ getStatus(props.row.status).name }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="goToEdit(props.row.id)">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />

                  <span>{{ $t('buttons.edit') }}</span>
                </b-dropdown-item>
                <!--                <b-dropdown-item>-->
                <!--                  <feather-icon-->
                <!--                    icon="TrashIcon"-->
                <!--                    class="mr-50"-->
                <!--                  />-->
                <!--                  <span>{{ $t('buttons.delete') }}</span>-->
                <!--                </b-dropdown-item>-->
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template slot="emptystate">
          <div class="vgt-center-align vgt-text-disabled">
            {{ $t('messages.noData') }}
          </div>
        </template>
        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              />
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <CustomPagination
                  :current-page="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  @page-change="onPageChange"
                />
                <!--                <b-pagination-->
                <!--                  v-model="currentPage"-->
                <!--                  :total-rows="totalRows"-->
                <!--                  :per-page="perPage"-->
                <!--                  first-number-->
                <!--                  last-number-->
                <!--                  class="mb-0 mt-1 mt-sm-0"-->
                <!--                  prev-class="prev-item"-->
                <!--                  next-class="next-item"-->
                <!--                  @change="onPageChange"-->
                <!--                >-->
                <!--                  <template #prev-text>-->
                <!--                    <feather-icon-->
                <!--                      icon="ChevronLeftIcon"-->
                <!--                      size="18"-->
                <!--                    />-->
                <!--                  </template>-->
                <!--                  <template #next-text>-->
                <!--                    <feather-icon-->
                <!--                      icon="ChevronRightIcon"-->
                <!--                      size="18"-->
                <!--                    />-->
                <!--                  </template>-->
                <!--                </b-pagination>-->

              </b-col>

            </b-row>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BCard, BRow, BCol, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
// eslint-disable-next-line import/extensions
import BCardCode from '@core/components/b-card-code/BCardCode'
import { ref } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import store from '@/store'
import UserListAddNew from './AdminListAddNew.vue'
import { codeColumnSearch } from '@/views/table/vue-good-table/code'
import IdentityDataService from '@/service/IdentityDataService'
import Shared from '@/shared'
import router from '@/router'
import CustomPagination from "@/views/components/custom-pagination/custom-pagination.vue"

export default {
  components: {
    CustomPagination,
    // UsersListFilters,
    UserListAddNew,

    // eslint-disable-next-line vue/no-unused-components
    BCardCode,
    VueGoodTable,
    BAvatar,
    BCard,
    BCol,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BBadge,
    flatPickr,
    BButton,
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BInputGroup,
    BInputGroupPrepend,

    vSelect,
  },
  setup() {
    const isAddNewUserSidebarActive = ref(false)

    return {
      isAddNewUserSidebarActive,
    }
  },
  data() {
    return {
      isLoading: false,
      dir: false,
      currentUser: null,
      rangeDate: null,
      codeColumnSearch,
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 10,
      currentPage: 1,
      rows: [],
      searchTerm: '',
      roleOptions: Shared.getAdminRolesForSelect(),
      planOptions: [
        { label: 'Basic', value: 'basic' },
        { label: 'Company', value: 'company' },
        { label: 'Enterprise', value: 'enterprise' },
        { label: 'Team', value: 'team' },
      ],
      serverParams: {
        columnFilters: {
        },
        sort: [
          {
            field: 'id',
            type: 'asc',
          },
        ],
        page: 1,
        perPage: 20,
      },
      status: Shared.getAdminStatuses(),
    }
  },
  computed: {
    columns() {
      return [
      {
        label: this.$t('fields.name'),
        field: 'fullName',
        filterOptions: {
          enabled: true,
          placeholder: this.$t('fields.search'),
        },
      },
      {
        label: this.$t('fields.email'),
        field: 'email',
        filterOptions: {
          enabled: true,
          placeholder: this.$t('fields.search'),
        },
      },
      {
        label: this.$t('fields.role'),
        field: 'role',
        filterOptions: {
          enabled: true,
          placeholder: this.$t('fields.select'),
          filterValue: null,
          filterDropdownItems: Shared.getAdminRolesForFilter(),
          trigger: 'enter',
        },
      },
      {
        label: this.$t('fields.lastLogin'),
        field: 'lastLoginDate',
        filterOptions: {
          enabled: true,
          customFilter: 'LastLoginDate',
        },
        formatFn: this.formatDate,
      },
      {
        label: this.$t('fields.status'),
        field: 'status',
        filterOptions: {
          enabled: true,
          placeholder: this.$t('fields.select'),
          filterValue: null,
          filterDropdownItems: Shared.getAdminStatusesForFilter(),
          trigger: 'enter',
        },
      },
      {
        label: this.$t('fields.action'),
        field: 'action',
        sortable: false,
        filterOptions: {
          enabled: false,
        },
      },
    ]
},
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  watch: {
    $route({ query }) {
      if (!query.page) {
        router.replace({
          query: { ...this.$route.query, page: 1 },
        })
      }
      if (query.page !== this.currentPage){
        this.currentPage = query.page
        const newProps = { page: query.page, perPage: this.perPage }
        this.updateParams({ ...this.serverParams, ...newProps })
        this.loadItems()
      }
    },
  },
  beforeCreate() {
    const router = this.$router
    const { query } = this.$route
    const { page } = query
    if (!query.page) {
      router.replace({
        query: { ...this.$route.query, page: 1 },
      })
    }
  },
  created() {
    const { query } = this.$route
    const { page } = query
    this.currentPage = +page
    const newProps = {
      page: query.page,
      perPage: this.perPage,
    }
    IdentityDataService.getAdminsList(newProps).then(response => {
      if (response.data.accepted) {
        this.totalRows = response.data.data[0].total
        this.rows = response.data.data[0].items
        const totalPage = Math.ceil(this.totalRows / this.serverParams.perPage)
        const { query } = this.$route
        if (totalPage < query.page) this.onPageChange(totalPage || 1)
        this.isLoading = false
      } else {
        this.isLoading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("toastMessages.error"),
            icon: 'BellIcon',
            text: response.data.errorMessages[0],
            variant: 'danger',
          },
        })
      }
    })
    IdentityDataService.getCurrent().then(response => {
      if (response.data.accepted) {
        this.currentUser = response.data.data[0]
      }
    })
  },
  methods: {
    filterDate(evn) {
      this.updateParams({
       ...this.serverParams,
        columnFilters: {
          LastLoginDate: String(this.rangeDate),
        },
      }, true)
      this.loadItems()
    },
    goToEdit(id) {
      if (this.currentUser.id === id) {
        router.push({ name: 'my-account' })
      } else {
        router.push({ name: 'admin-edit', params: { id } })
      }
    },
    getStatus(data) {
      return Shared.resolveAdminStatusVariant(data)
    },
    formatDate(value) {
      return new Date(value).toLocaleDateString()
    },
    updateParams(newProps, update) {
      if (update) {
        this.serverParams = { ...this.serverParams, ...newProps }
      } else {
        this.serverParams = newProps
      }
    },

    onPageChange(params) {
      const router = this.$router
      router.replace({
        query: { ...this.$route.query, page: params },
      })
    },

    onPerPageChange(params) {
      this.perPage = params
      this.updateParams({ page: this.currentPage, perPage: params })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        ...this.serverParams,
        sort: [{
          type: params[0].type,
          field: params[0].field === 'fullName' ? 'firstName' : params[0].field,
        }],
      }, false)
      this.loadItems()
    },

    onColumnFilter(params) {
      if ('status' in params.columnFilters) {
        this.removeEmpty(params.columnFilters)
        if (params.columnFilters.status === "") {
          this.updateParams({
            page: 1,
            perPage: 10,
          }, true)
          this.loadItems()
        } else {
          this.updateParams(params, true)
          this.loadItems()
        }
      } else {
        this.updateParams(params, true)
        this.loadItems()
      }
    },
    removeEmpty(obj) {
      Object.keys(obj).forEach(k => (!obj[k] && obj[k] !== undefined) && delete obj[k])
      return obj
    },
    reloadTableData() {
      IdentityDataService.getAdminsList(this.serverParams).then(response => {
        if (response.data.accepted) {
          this.totalRows = response.data.data[0].total
          this.rows = response.data.data[0].items
          this.isLoading = false
        } else {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("toastMessages.error"),
              icon: 'BellIcon',
              text: response.data.errorMessages[0],
              variant: 'danger',
            },
          })
        }
      })
    },
    loadItems() {
      IdentityDataService.getAdminsList(this.serverParams).then(response => {
        if (response.data.accepted) {
          this.totalRows = response.data.data[0].filteredTotal
          this.rows = response.data.data[0].items
          const totalPage = Math.ceil(this.totalRows / this.serverParams.perPage)
          if (totalPage < this.serverParams.page) this.onPageChange(totalPage || 1)
          this.isLoading = false
        } else {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: response.data.errorMessages[0],
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
